<template>
          <div class="container mid_margin" style="margin-top:16px auto">
        <!-- Breadcrumb End-->
        <div class="row mt-4">
          <!--Middle Part Start-->
          <div id="content ">
            <div class="row" style="justify-content:center">
              <div v-if="show_login" class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div class="custom-box">
                  <h4
                    class="title"
                    style="margin-bottom: 2px; border-bottom: none"
                  >
                    {{ heading }}
                  </h4>
                  <span style="margin-left: 40px; margin-top: 2px"> </span>
                  <br />
                  <br />
                  <form v-if="sendMode" @submit.prevent="sendOtp" method="post">

                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-8"></div>
                      </div>
                      <label for="mobile_no">{{ span_message }}</label>
                      <br/>
                      <br/>
                      <input
                        type="text"
                        name="mobile_no"
                        class="form-control rounded"
                        maxlength="11"
                        :class="{ 'is-invalid': form.errors.has('mobile_no') }"
                        v-model="form.mobile_no"
                        placeholder="01xxx-xxxxxx"
                      />
                      <has-error :form="form" field="mobile_no"></has-error>
                    </div>
                     <div class="form-group text-center mt-1 mb-1">
                        <br>
                     <button
                      class="btn btn-sm text-white btn-block btn-primary"
                      type="submit"
                      :disabled="form.busy"
                    >
                      <i class="fa fa-spinner fa-spin" v-if="form.busy"></i>SEND
                      CODE
                    </button>

                     </div>
                    <div class="form-group text-center mt-3">
                         <router-link :to="{ name: 'login' }" class="registerNew">Login With <strong>Email/Mobile & Password ?</strong> </router-link>
                    </div>
                  </form>
                  <form @submit.prevent="verifyOtp" v-else>

                    <div class="form-group mb-3">
                      <input
                        type="text"
                        name="verify_code"
                        class="form-control rounded"
                        autocomplete="off"
                        :class="{
                          'is-invalid': form.errors.has('verify_code'),
                        }"
                        v-model="verify_code"
                        placeholder="Enter Code"
                      />
                      <has-error :form="form" field="verify_code"></has-error>
                    </div>
                   <div class="form-group text-center mb-3">
                      <button
                      class="btn btn-sm btn-block btn-primary"
                      type="submit"
                      :disabled="verify_code.length != 4"
                    >
                      VERIFY
                    </button>
                   </div>

                    <div class="form-group text-center">
                      <button
                      :disabled="resend_disable"
                      class="btn btn-sm btn-block btn-primary" >
                      Resend Code
                      <small v-if="time_count > 0"
                        >{{ "(" + time_count + ")" }}
                      </small>
                    </button>
                    </div>
                  </form>
                  <hr>
                </div>
              </div>
              <div class="col-lg-12 text-center mt-2 mb-4" v-else >
               <img width="100%" src="https://media.istockphoto.com/id/1261698208/vector/coming-soon-ink-brush-vector-lettering-promotion-or-announcement-banner-modern-vector.jpg?s=612x612&w=0&k=20&c=nKUyh-A4a65lkwZDz7nb2aBXfBIY7-NinZJkjnItEQg=" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import Vue from "vue";
import { Form, HasError } from "vform";
import ObjectToFD from 'vue-object-to-formdata';
Vue.use(ObjectToFD);
export default {
  components:{
   'has-error': HasError,
  },
  data() {
    return {
      form: new Form({
        mobile_no: "",
      }),
      show_login: false,
      disabled: true,
      sendMode: true,
      verify_code: "",
      span_message: "MOBILE NUMBER",
      heading: "LOGIN WITH MOBILE NUMBER",
      time_count: 20,
      resend_disable: true,
      pre_route: "",
    };
  },
  methods: {
   async sendOtp() {
    await   this.axios.get("/send/otp",{
        params : { mobile_no : this.form.mobile_no }
      })
        .then((resp) => {
          console.log(resp);
          if (resp.data.status=='SUCCESS') {
            this.sendMode = false;
            this.$toast.success(resp.data.message);
            this.$refs.verify_code;
            this.span_message = "PLEASE ENTER 4 DIGITS VERIFICATION CODE";
            this.heading = "ENTER CODE";
            this.resend_disable = true;
            this.timeCount();
          }
        })

        .catch(error =>{
           this.$toast.error(error.response.data.message);
        })

    },
    verifyOtp() {
    //   this.isLoading = true;
      this.axios.get("/verify/otp/code", {
          params: {
            mobile_no: this.form.mobile_no,
            verify_code: this.verify_code,
          },
        })
        .then((resp) => {
        //   this.isLoading = false;
            this.$toast.success(resp.data.message);
            localStorage.setItem("user_token", resp.data.token);

            let pushName =  (this.$store.getters.cartContent.total > 0 ? "Checkout" : "UserDashboard")
            this.$router.push({ name: pushName })

 
        })

    },
    timeCount() {
      if (parseInt(this.time_count) != 60) {
        this.time_count = 60;
      }
      setInterval(() => {
        this.time_count--;
      }, 1000);
    },
  },
  watch: {
    time_count: function (value) {
      if (parseInt(value) <= parseInt(0)) {
        this.resend_disable = false;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pre_route = from.name;
    });
  },
};
</script>
<style>
  .mid_margin{
    margin: auto;
  }
</style>